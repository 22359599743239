<template>
  <div class="">
    <div class="main-wrap">
      <div class="top-nav-wrap">
        <router-link to="/3d-tour" class="top-menu-btn">
          <img src="../assets/back.svg" />Back
        </router-link>
        <h1 class="top-small-head">South Africa Emseni</h1>
      </div>
      <div class="top-grad"></div>
      <div class="main-media">
        <iframe
          class="iframe"
          src="https://my.matter.co.il/tour/sa"
          title="South Africa"
        ></iframe>
      </div>
      <img src="../assets/logo.svg" class="logo" />
      <img src="../assets/Orbia_Logomark_White.png" class="logo2" />    </div>
  </div>
</template>
<script>
export default {
  name: "3D Tour",
};
</script>
