<template>
  <div class="wrapper" :class="{ fullyLoaded: prog === 100 }">
    <div class="filler" :style="{ width: prog + '%' }" />
  </div>
</template>

<script>
export default {
  name: "loading-bar",
  props: {
    progress: Number,
  },
  data() {
    return {
      prog: this.progress,
    };
  },
  watch: {
    progress(v) {
      if (v != this.prog) this.prog = v;
    },
  },
};
</script>

<style scoped>
.wrapper {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: all ease-in-out;
}

.fullyLoaded {
  opacity: 0;
}
.filler {
  height: 4px;
  background: #f69321;
}
</style>
