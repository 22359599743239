import Bagel from "@bageldb/bagel-db";
import Localbase from "localbase";
import Vue from "vue";

const $db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN);
const $localDB = new Localbase("netafim");

const state = {
  allVideos: {},
  clearData: false,
  progress: {},
};
const getters = {
  videoProgress: (state) => (slug) => state.progress[slug] || 0,
};
const actions = {
  clearLocalData() {
    $localDB.collection("videos").delete();
    this.clearData = false;
  },
  async urlToBase64({ commit }, video) {
    const response = await fetch(video.url);
    const reader = response.body.getReader();
    const contentLength = +response.headers.get("Content-Length");
    let receivedLength = 0;
    let chunks = [];
    let isDone = false;
    while (!isDone) {
      const { done, value } = await reader.read();
      isDone = !!done;
      if (done) break;

      chunks.push(value);
      receivedLength += value.length;
      commit("SET_PROGRESS", {
        slug: video.slug,
        progress: (receivedLength / contentLength) * 100,
      });
    }
    const blob = new Blob(chunks, { type: `video/${video.ext}` });
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  },
  async downloadAllVideos({ dispatch, state, commit }) {
    const { data } = await $db
      .collection("video")
      .query("draft", "=", false)
      .get();
    for (let item of data.filter(
      (d) => !Object.keys(state.progress).includes(d.slug)
    )) {
      await $localDB.collection("videos").doc(item.slug).get();
      const video = await $localDB
        .collection("videos")
        .doc(item.slug)
        .get()
        .catch((err) => console.log(err));
      if (video && video.base64) {
        commit("SET_VIDEO", video);
      } else {
        commit("SET_VIDEO", item);
        commit("SET_PROGRESS", { slug: item.slug, progress: 0 });
        item.ext = item.url.split(".").pop();
        dispatch("urlToBase64", item).then((base64) => {
          state.allVideos[item.slug].base64 = base64;
          $localDB
            .collection("videos")
            .doc(item.slug)
            .set(state.allVideos[item.slug]);
        });
      }
    }
  },
};
const mutations = {
  SET(state, { key, value }) {
    state[key] = value;
  },
  SET_VIDEO(state, video) {
    state.allVideos[video.slug] = video;
  },
  SET_PROGRESS(state, { slug, progress }) {
    Vue.set(state.progress, slug, progress);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
