<template>
  <div class="">
    <div class="main-wrap">
      <div class="top-nav-wrap">
        <router-link to="/" class="top-menu-btn">
          <img src="../assets/menu.svg" />Menu
        </router-link>
        <h1 class="top-small-head">3D Tour</h1>
      </div>
      <div class="top-grad"></div>
      <!-- <div class="nav-btns-wrap">
        <a href="#" class="nav-btn"> <img src="../assets/back.svg" /></a>
        <a href="" class="nav-btn"> <img src="../assets/next.svg" /></a>
      </div> -->
      <div class="main-media">
        <!-- <img src="../assets/greenhouse-stage-4.png" /> -->
        <iframe
          class="iframe"
          src="https://my.matter.co.il/tour/0dc41d12?s=69635fa9f99a44a28f165d6f2b06f38c&x=-0.7477081732827228&y=-11.785717923912072"
          title="W3Schools Free Online Web Tutorials"
        ></iframe>
      </div>
      <!-- <div class="index-media">
        <a href="" class="link-thumbnail">
          <img src="../assets/greenhouse-stage-1.png" /></a
        ><a href="" class="link-thumbnail">
          <img src="../assets/greenhouse-stage-3-1.png" /></a
        ><a href="" class="link-thumbnail active">
          <img src="../assets/greenhouse-stage-4.png" /></a
        ><a href="" class="link-thumbnail">
          <img src="../assets/greenhouse-stage-2.png"
        /></a>
      </div> -->
      <!-- <div class="bot-grad"></div> -->
      <img src="../assets/logo.svg" class="logo" />
      <img src="../assets/Orbia_Logomark_White.png" class="logo2" />    </div>
  </div>
</template>
<script>
export default {
  name: "3D Tour",
};
</script>
