<template>
  <div class="">
    <div class="main-wrap">
      <div class="top-nav-wrap">
        <router-link to="/" class="top-menu-btn white">
          <img src="../assets/menu.svg" />Menu
        </router-link>
        <h1 class="top-small-head">{{ project.videoTitle }}</h1>
      </div>
      <div class="top-grad"></div>
      <div class="main-media">
        <video v-if="src" autoplay="" controls="" :src="src">
          The “video” tag is not supported by your browser.
        </video>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Video",
  data() {
    return {
      project: {},
    };
  },
  computed: {
    src() {
      if (this.project && this.project.base64) return this.project.base64;
      return this.project?.videoLink || "";
    },
  },
  async beforeMount() {
    const { slug } = this.$route.params;
    this.project = await this.$localDB.collection("menuLayout").doc(slug).get();
    if (!this.project?.videoLink) {
      const { data } = await this.$db
        .collection("menuLayout")
        .query("slug", "=", slug)
        .get();
      this.project = data[0];
    }
  },
};
</script>

<style>
video {
  width: 100vw;
  height: 100vh;
}
</style>
