<template>
  <div class="" v-if="videos !== {}">
    <div class="full-scr" :class="{ open: fullScreen == false && !dismissedFullscreen }">
      <p>Make this page</p>
      <button @click="makeFullscreen" class="full-scr-btn">Full Screen</button>
      <button @click="dismissedFullscreen = true" class="full-scr-close">
        <img src="../assets/close.svg" alt="Close" />
      </button>
    </div>
    <div class="main-wrap light">
      <div class="new-menu-wrap">
        <div class="new-head-wrap">
          <div class="new-head-side"></div>
          <h1 class="new-head">Gakon Netafim</h1>
        </div>
        <div class="boxes-wrap">

          <router-link to="/video/start" class="video-box2 big1" :class="{ loaded: videoProgress('start') === 100 }">
            <h3 class="video-box-head">
              Gakon Netafim Video<br />
              "From Start to Success We've got you Covered"
            </h3>
            <div class="video-grad"></div>
            <img src="../assets/start.jpg" class="video-bg-img" />
            <LoadingBar :progress="videoProgress('start')" />
          </router-link>

          <router-link to="/presentation" class="video-box2 loaded">
            <h3 class="video-box-head">Presentation</h3>
            <div class="video-grad"></div>
            <img src="../assets/gakon.jpeg" class="video-bg-img" />
          </router-link>

          <a href="https://www.netafim.com/en/greenhouse/" target="_blank" class="video-box2">
            <h3 class="video-box-head loaded">Website</h3>
            <div class="video-grad"></div>
            <img src="../assets/website.jpg" class="video-bg-img" />
          </a>

          <router-link to="/video/map" class="video-box2 loaded">
            <h3 class="video-box-head">World Map</h3>
            <div class="video-grad"></div>
            <img src="../assets/map.jpg" class="video-bg-img" />
          </router-link>

        </div>
      </div>

      <div class="new-menu-wrap wide">
        <div class="new-head-wrap">
          <div class="new-head-side"></div>
          <h1 class="new-head">Greenhouse Projects</h1>
        </div>
        <div class="boxes-wrap biggerold">

          <router-link to="/video/netafim" class="video-box2" :class="{
            loaded: videoProgress('netafim') === 100,
          }">
            <LoadingBar :progress="videoProgress('netafim')" />
            <h3 class="video-box-head">Greenhouse Benefits</h3>
            <div class="video-grad"></div>
            <img src="../assets/video pics/07_01_Netafim_Green-house.jpg" class="video-bg-img" />
          </router-link>

          <router-link to="/3d-tour" class="video-box2 big3 loaded">
            <h3 class="video-box-head">3D Tours</h3>
            <div class="video-grad"></div>
            <img src="../assets/bg.jpg" class="video-bg-img" />
          </router-link>


          <router-link to="/video/croatia" class="video-box2" :class="{
            loaded: videoProgress('croatia') === 100,
          }">
            <h3 class="video-box-head">Agrokor <br />Croatia</h3>
            <div class="video-grad"></div>
            <img src="../assets/video pics/Croatia-Tomatoes-greenhouse-projects-.jpg" class="video-bg-img" />
            <LoadingBar :progress="videoProgress('croatia')" />
          </router-link>

          <router-link to="/video/building" class="video-box2" :class="{
            loaded: videos.building && videos.building.progress === 100,
          }">
            <h3 class="video-box-head">Building a Greenhouse</h3>
            <div class="video-grad"></div>
            <img src="../assets/video pics/GAKON--building-a-greenhouse.jpg" class="video-bg-img" />
          </router-link>

          <router-link :class="{
            loaded: videoProgress('vietnam') === 100,
          }" to="/video/vietnam" class="video-box2">
            <h3 class="video-box-head">
              VinEco <br />
              Vietnam
            </h3>
            <div class="video-grad"></div>
            <img src="../assets/video pics/Vietnam-greenhouse-project.jpg" class="video-bg-img" />
            <LoadingBar :progress="videoProgress('vietnam')" />
          </router-link>

          <router-link to="/video/cannabis" :class="{
            loaded: videos.cannabis && videos.cannabis.progress === 100,
          }" class="video-box2">
            <h3 class="video-box-head">Medical Cannabis in Greenhouse</h3>
            <div class="video-grad"></div>
            <img src="../assets/cannabis.jpg" class="video-bg-img" />
            <LoadingBar :progress="videoProgress('cannabis')" />
          </router-link>

          <router-link to="/video/atyau" :class="{
            loaded: videoProgress('atyau') === 100,
          }" class="video-box2">
            <h3 class="video-box-head">Atyau Sauda Kazakhstan</h3>
            <div class="video-grad"></div>
            <img src="../assets/video pics/Atyau-Kazakhstan-Glasshouse-Project.jpg" class="video-bg-img" />
            <LoadingBar :progress="videoProgress('atyau')" />
          </router-link>

          <router-link :class="{
            loaded: videos.netbow && videos.netbow.progress === 100,
          }" to="/video/netbow" class="video-box2">
            <h3 class="video-box-head">Netbow</h3>
            <div class="video-grad"></div>
            <img src="../assets/netbow.jpg" class="video-bg-img" />
            <LoadingBar :progress="videoProgress('netbow')" />
          </router-link>

          <router-link :class="{
            loaded: videos.cottonex && videos.cottonex.progress === 100,
          }" to="/video/cottonex" class="video-box2">
            <h3 class="video-box-head">Cottonex</h3>
            <div class="video-grad"></div>
            <img src="../assets/cottonex.jpg" class="video-bg-img" />
            <LoadingBar :progress="videoProgress('cottonex')" />
          </router-link>
        </div>
      </div>




      <img src="../assets/logo.svg" class="logo" />
      <img src="../assets/Orbia_Logomark_White.png" class="logo2" />
        </div>
        
    <div class="settings">
      <button @click="clearData = true">⚙</button>
      <button v-if="clearData" @click="clearLocalData">Clear All Cache</button>
    </div>
  </div>
</template>

<script>
import LoadingBar from "../components/loadingBar.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  data: () => ({
    videos: {},
    clearData: false,
    dismissedFullscreen: false,
    fullScreen: null,
  }),
  computed: {
    ...mapGetters(["videoProgress"]),
  },
  mounted() {
    this.checkifFullScreen();
    document.body.addEventListener("fullscreenchange", () =>
      this.checkifFullScreen()
    );
    this.$store.dispatch("downloadAllVideos");
  },
  methods: {
    clearLocalData() {
      this.$localDB.collection("videos").delete();
      this.clearData = false;
      window.location.reload();
    },
    makeFullscreen() {
      document.body.requestFullscreen();
    },
    checkifFullScreen() {
      setTimeout(() => {
        this.fullScreen =
          window.innerWidth == screen.width &&
          window.innerHeight == screen.height;
      }, 500);
    },
  },
  components: { LoadingBar },
};
</script>

<style>
.settings {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: black;
  color: white;
  padding: 7px 20px;
  z-index: 99999;
}

.settings button {
  background: transparent;
}
</style>
