import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
Vue.use(VueRouter);
import Bagel from "@bageldb/bagel-db";
import Localbase from "localbase";
import defaultStore from "./stores/index";

import Vuex from "vuex";

Vue.use(Vuex);
const store = new Vuex.Store(defaultStore);

Vue.prototype.$db = new Bagel(process.env.VUE_APP_BAGEL_TOKEN);
Vue.config.productionTip = false;
Vue.prototype.$localDB = new Localbase("netafim");

const app = new Vue({
  router,
  store,
  render: (h) => h(App),
});
app.$mount("#app");
