<template>
  <div class="">
    <div class="main-wrap">
      <div class="top-grad"></div>
      <div class="main-menu-wrap">
        <p class="menu-head">Gakon Netafim</p>
        <router-link to="/company-profile" class="menu-opt">
          <img src="../assets/icon1.svg" class="menu-icon" />
          <div class="menu-opt-end"></div>
          <div class="menu-opt-arrow-box"><img src="../assets/next.svg" /></div>
          Who we are</router-link
        >
        <router-link to="/main-video" class="menu-opt">
          <img src="../assets/icon2.svg" class="menu-icon" />
          <div class="menu-opt-end"></div>
          <div class="menu-opt-arrow-box"><img src="../assets/next.svg" /></div>
          Gakon Netafim Video
        </router-link>
        <router-link to="/presentation" class="menu-opt">
          <img src="../assets/icon3.svg" class="menu-icon" />
          <div class="menu-opt-end"></div>
          <div class="menu-opt-arrow-box"><img src="../assets/next.svg" /></div>
          Gakon Netafim Online
        </router-link>
        <p class="menu-head">Greenhouse Projects</p>
        <router-link to="/video-gallery" class="menu-opt">
          <img src="../assets/icon4_1.svg" class="menu-icon" />
          <div class="menu-opt-end"></div>
          <div class="menu-opt-arrow-box"><img src="../assets/next.svg" /></div>
          Gakon Netafim Projects Videos
        </router-link>
        <router-link to="/3d-tour" class="menu-opt">
          <img src="../assets/icon4.svg" class="menu-icon" />
          <div class="menu-opt-end"></div>
          <div class="menu-opt-arrow-box"><img src="../assets/next.svg" /></div>
          3d Project Tours</router-link
        >
        <!-- <router-link to="/product-catalogue" class="menu-opt">
          <img src="../assets/icon.svg" class="menu-icon" />
          <div class="menu-opt-end"></div>
          <div class="menu-opt-arrow-box"><img src="../assets/next.svg" /></div>
          Product catalogue
        </router-link> -->
      </div>
      <img src="../assets/logo.svg" class="logo" />
      <img src="../assets/Orbia_Logomark_White.png" class="logo2" />      <div class="bg-side-image-wrap">
        <div class="mid-skew"></div>
        <div class="bg-img-skew"></div>
        <img src="../assets/bg.jpg" class="big-side-img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
};
</script>
