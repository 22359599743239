<template >
  <div class="">
    <div class="main-wrap">
      <div class="top-nav-wrap">
        <router-link to="/" class="top-menu-btn white">
          <img src="../assets/menu.svg" />Menu
        </router-link>
        <h1 class="top-small-head">Video</h1>
      </div>
      <div class="top-grad"></div>
      <!-- <div class="nav-btns-wrap">
        <a href="#" class="nav-btn"> <img src="../assets/back.svg" /></a>
        <a href="" class="nav-btn"> <img src="../assets/next.svg" /></a>
      </div> -->
      <div class="main-media">
        <div class="yt-wrap">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/QWgsePEcfTc"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <!-- <div class="index-media">
        <a href="" class="link-thumbnail"> <img src="../assets/bg.jpg" /></a
        ><a href="" class="link-thumbnail active">
          <img src="../assets/bg.jpg" /></a
        ><a href="" class="link-thumbnail"> <img src="../assets/bg.jpg" /></a
        ><a href="" class="link-thumbnail"> <img src="../assets/bg.jpg" /></a
        ><a href="" class="link-thumbnail"> <img src="../assets/bg.jpg" /></a
        ><a href="" class="link-thumbnail"> <img src="../assets/bg.jpg" /></a
        ><a href="" class="link-thumbnail"> <img src="../assets/bg.jpg" /></a
        ><a href="" class="link-thumbnail"> <img src="../assets/bg.jpg" /></a>
      </div> -->
      <div class="bot-grad"></div>
      <img src="../assets/logo.svg" class="logo" />
      <img src="../assets/Orbia_Logomark_White.png" class="logo2" />    </div>
  </div>
</template>
<script>
export default {
  name: "Main Video",
};
</script>



