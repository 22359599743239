import menu from "../views/Menu.vue";
import mainvideo from "../views/Mainvideo.vue";
import video from "../views/Video.vue";
import videos from "../views/Videos.vue";
import productcatalogue from "../views/Productcatalogue.vue";
import brochure from "../views/Brochure.vue";
import threeDtour from "../views/threeDtour.vue";
import companyprofile from "../views/Companyprofile.vue";
import presentation from "../views/Project.vue";
import presentations from "../views/Presentation.vue";

import videogallery from "../views/Videogallery.vue";
import threeD from "../views/threeD.vue";
import threeD1 from "../views/threeD1.vue";
import threeD2 from "../views/threeD2.vue";
import threeD3 from "../views/threeD3.vue";
import threeD4 from "../views/threeD4.vue";
import threeD5 from "../views/threeD5.vue";
import website from "@/views/website.vue";
import threedgallery from "../views/threedgallery.vue";
import threedgallerys from "../views/threedgallerys.vue";

import Home from "../views/Home.vue";


import Vue from "vue";
import VueRouter from "vue-router";
import Homeold from "../views/Home-old2.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/old",
    name: "Home",
    component: Homeold,
  },
  {
    path: "/3d-tour",
    name: "threedgallery",
    component: threedgallery,
  },
  {
    path: "/3d-tours/:slug",
    name: "threedgallerys",
    component: threedgallerys,
  },
  {
    path: "/3d/:slug",
    name: "threeD",
    component: threeD,
  },
  {
    path: "/3d-1",
    name: "threeD1",
    component: threeD1,
  },
  {
    path: "/3d-2",
    name: "threeD2",
    component: threeD2,
  },
  {
    path: "/3d-3",
    name: "threeD3",
    component: threeD3,
  },
  {
    path: "/3d-4",
    name: "threeD4",
    component: threeD4,
  },
  {
    path: "/3d-5",
    name: "threeD5",
    component: threeD5,
  },
  {
    path: "/video-gallery",
    name: "Videogallery",
    component: videogallery,
  },
  {
    path: "/presentation",
    name: "Presentation",
    component: presentation,
  },
  {
    path: "/presentations/:slug",
    name: "Presentations",
    component: presentations,
  },
  {
    path: "/company-profile",
    name: "Companyprofile",
    component: companyprofile,
  },
  {
    path: "/website",
    name: "website",
    component: website,
  },
  {
    path: "/3d-tour1",
    name: "threeDtour",
    component: threeDtour,
  },
  {
    path: "/brochure",
    name: "Brochure",
    component: brochure,
  },
  {
    path: "/product-catalogue",
    name: "Productcatalogue",
    component: productcatalogue,
  },
  {
    path: "/video/:slug",
    name: "Video",
    component: video,
  },
  {
    path: "/videos/:slug",
    name: "Videos",
    component: videos,
  },
  {
    path: "/main-video",
    name: "Mainvideo",
    component: mainvideo,
  },
  {
    path: "/menu",
    name: "Menu",
    component: menu,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
