<template >
  <div
      class=""
      
  >
    <div
        class="main-wrap"
        
    >
      <div
          class="top-nav-wrap"
          
      >
        <router-link
            to="/"
            class="top-menu-btn"
            
        >
          <img
              src="../assets/menu.svg"
              
           />Menu
        </router-link>
        <h1
            class="top-small-head"
            
        >Brochure</h1>
      </div>
      <div
          class="top-grad"
          
      ></div>
      <div
          class="nav-btns-wrap"
          
      >
        <a
            href="#"
            class="nav-btn"
            
        > <img
     src="../assets/back.svg"
     
  /></a>
        <a
            href=""
            class="nav-btn"
            
        > <img
     src="../assets/next.svg"
     
  /></a>
      </div>
      <div
          class="main-media"
          
      >
        <img
            src="../assets/cmt_catalog_online_Page_01.jpg"
            
         />
      </div>
      <div
          class="index-media"
          
      >
        <a
            href=""
            class="link-thumbnail"
            
        >
          <img
              src="../assets/cmt_catalog_online_Page_02.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail active"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_01.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_03.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_04.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_05.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_06.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_07.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_08.jpg"
              
           /></a>
        <a
            href=""
            class="link-thumbnail"
            
        >
          <img
              src="../assets/cmt_catalog_online_Page_09.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_10.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_11.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_12.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_13.jpg"
              
           /></a><a
                     href=""
                     class="link-thumbnail"
                     
                 >
          <img
              src="../assets/cmt_catalog_online_Page_14.jpg"
              
           /></a>
      </div>
      <div
          class="bot-grad"
          
      ></div>
      <img
          src="../assets/logo.svg"
          class="logo"
          
       />
    </div>
  </div>
</template>
<script>
export default {
  name: "Product Catalogue",
};
</script>



