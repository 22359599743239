<template>
  <div class="">
    <div class="main-wrap">
      <div class="top-nav-wrap">
        <router-link to="/" class="top-menu-btn">
          <img src="../assets/menu.svg" />Menu
        </router-link>
        <h1 class="top-small-head">{{ project.label }}</h1>
      </div>
      <div class="top-grad"></div>
      <img src="../assets/logo.svg" class="logo" />
      <img src="../assets/Orbia_Logomark_White.png" class="logo2" />      <div class="td-wrap">
        <router-link v-for="menuItem in project.submenu" :key="menuItem._id" :to="`/3d/${project._id}.${menuItem._id}`" class=" video-box">
          <h3 class="video-box-head" v-html="menuItem.title"></h3>
          <div class="video-grad"></div>
          <img :src="menuItem.thumbnail.imageURL" class="video-bg-img" />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "3D Gallery",
  data() {
    return {
      project: {},
    };
  },
  async beforeMount() {
    const { slug } = this.$route.params;
    if (!this.project?.presentationImages) {
      const { data } = await this.$db
        .collection("menuLayout")
        .query("slug", "=", slug)
        .everything()
        .get();
      this.project = data[0];
    }
  },
};
</script>
