<template>
  <div class="body">
    <div class="top-nav-wrap">
      <router-link to="/" class="top-menu-btn">
        <img src="../assets/menu.svg" />Menu
      </router-link>
      <h1 class="top-small-head">{{ project.label }}</h1>
    </div>
    <button v-if="slide > 1" class="nav-btn" @click="slide--">
      <img src="../assets/back.svg" />
    </button>
    <button v-if="slide < project.presentationImages.length - 1" class="nav-btn next" @click="slide++">
      <img src="../assets/next.svg" />
    </button>
    <CarouselVue v-if="project.presentationImages" ref="carousel" class="proj-main-wrap" :loop="false" :autoWidth="true" :uRLhashListener="true" :dots="false" v-model="slide" :items="1"
      :navigation="true" :animateOut="'fadeOut'">
      <!-- :style="{ height: image.height + 'px' }" -->
      <div class="proj-item" :data-hash="i" :class="{
        full: !image.height || image.height == 100,
      }" v-for="(image, i) of images" :key="image._id">
        <div style="overflow: hidden" class="proj-img" v-if="image.customPosition && image.customPosition.scale">
          <div style="transform-origin: 50% 50%" :style="{
            transform: `scale(${image.customPosition.scale}) translate(${image.customPosition.x}, ${image.customPosition.y})`,
          }">
            <img :src="image.imageURL + '&resize=2500'" alt="" />
          </div>
        </div>
        <img v-else :src="image.imageURL + '&resize=1600'" alt="" class="proj-img" />
        <div class="proj-txt-wrap">
          <p class="proj-txt">
            {{ image.description }}
          </p>
        </div>
      </div>
    </CarouselVue>

  </div>
</template>

<script>
import CarouselVue from "../components/CarouselVue.vue";
export default {
  components: { CarouselVue },
  async beforeMount() {
    const { slug } = this.$route.params;
    if (!this.project?.presentationImages) {
      const { data } = await this.$db
        .collection("menuLayout")
        .query("slug", "=", slug)
        .get();
      this.project = data[0];
    }
  },
  data() {
    return {
      animateOut: false,
      slide: 0,
      hover: -2,
      project: {},
    };
  },
  watch: {
    route() {
      let { hash } = window.location;
      if (hash) {
        let slide = parseInt(hash.replace("#", ""));
        setTimeout(() => this.goToSlide(slide + 1), 100);
      }
    },
  },
  computed: {
    images() {
      return this.project.presentationImages;
    },
  },
  methods: {
    goToSlide(i) {
      console.log(i);
      if (Math.abs(this.slide - i) > 5) this.animateOut = "fadeOut";
      this.slide = i;
      setTimeout(() => {
        this.slide = i;
      }, 10);
      setTimeout(() => (this.animateOut = false), 900);
    },
  },
};
</script>

<style>
.proj-main-wrap {
  height: 86vh;
  margin-top: 7vh;
}

.zoomed-in {
  height: 55px;
  margin: 2px;
  line-height: 54px;
  padding: 13px 22px;
  background-color: rgba(34, 34, 34, 0.8);
  vertical-align: top;
}

.bigjump .owl-stage {
  transition: none !important;
}

.full {
  width: 100vw;
  height: 86vh;
}

.full img {
  height: 100%;
  object-fit: scale-down;
}

.body {
  background: radial-gradient(circle,
      rgba(29, 100, 177, 1) 0%,
      rgba(21, 70, 123, 1) 85%,
      rgba(18, 64, 113, 1) 100%);
  height: 100vh;
  align-items: center;
  position: relative;
  overflow: hidden;
  background: #353750

}

.nav-btn {
  position: fixed;
  top: 45vh;
  left: 0;
  z-index: 999999;
}

.nav-btn.next {
  left: auto;
  right: 0;
}

.owl-carousel span {
  z-index: 999999;
}
</style>
