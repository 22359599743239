<template >
  <div class="">
    <div class="main-wrap">
      <div class="top-nav-wrap">
        <router-link to="/" class="top-menu-btn">
          <img src="../assets/menu.svg" />Menu
        </router-link>
        <h1 class="top-small-head">Company Profile</h1>
      </div>
      <div class="top-grad"></div>
      <div class="nav-btns-wrap">
        <a href="#" class="nav-btn"> <img src="../assets/back.svg" /></a>
        <a href="" class="nav-btn"> <img src="../assets/next.svg" /></a>
      </div>
      <div class="main-media"><img src="../assets/image1.jpg" /></div>
      <div class="index-media">
        <a href="" class="link-thumbnail">
          <img src="../assets/gh-structre5.jpg" /></a
        ><a href="" class="link-thumbnail active">
          <img src="../assets/image1.jpg" /></a
        ><a href="" class="link-thumbnail">
          <img src="../assets/about-us.jpg" /></a
        ><a href="" class="link-thumbnail">
          <img src="../assets/Brochure1.jpg" /></a
        ><a href="" class="link-thumbnail">
          <img src="../assets/hero-water-1.jpg" /></a
        ><a href="" class="link-thumbnail">
          <img src="../assets/management1.jpg" /></a
        ><a href="" class="link-thumbnail">
          <img
            src="../assets/innovative-heating-systems-for-greenhouses.jpg" /></a
        ><a href="" class="link-thumbnail"> <img src="../assets/bg.jpg" /></a>
      </div>
      <div class="bot-grad"></div>

      

    </div>
  </div>
</template>
<script>
export default {
  name: "Company Profile",
};
</script>



