<template >
  <div class="">
    <div class="main-wrap">
      <div class="top-nav-wrap">
        <router-link to="/" class="top-menu-btn">
          <img src="../assets/menu.svg" />Menu
        </router-link>
        <h1 class="top-small-head">Video Gallery</h1>
      </div>
      <div class="top-grad"></div>
      <img src="../assets/logo.svg" class="logo" />
      <img src="../assets/Orbia_Logomark_White.png" class="logo2" />      <div class="videos-wrap">
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img
            src="../assets/2-cd-guzman-jaslico-mexico.ries.jpg"
            class="video-bg-img"
          />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img
            src="../assets/20180522_103734014_ios.jpg"
            class="video-bg-img"
          />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img
            src="../assets/A2179E6D-7E28-43E4-A7A45FA6177AC916-china-baofeng-solar-panel-project.jpg"
            class="video-bg-img"
          />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img src="../assets/structure-1.jpg" class="video-bg-img" />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img src="../assets/gh-technology2-1.jpg" class="video-bg-img" />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img src="../assets/about-us.jpg" class="video-bg-img" />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img
            src="../assets/web---small-strawberries-herefordshire-uk---shoot-2---52-1.jpg"
            class="video-bg-img"
          />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img src="../assets/structure2.jpg" class="video-bg-img" />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img
            src="../assets/2-cd-guzman-jaslico-mexico.ries.jpg"
            class="video-bg-img"
          />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img
            src="../assets/cooling-pad-and-fan-systems.jpg"
            class="video-bg-img"
          />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img src="../assets/img_4510.jpg" class="video-bg-img" />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img src="../assets/hero-water-1.jpg" class="video-bg-img" />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img src="../assets/hero-water-1.jpg" class="video-bg-img" />
        </router-link>
        <router-link to="/video" class="video-box">
          <h3 class="video-box-head">Header</h3>
          <div class="video-grad"></div>
          <img src="../assets/hero-water-1.jpg" class="video-bg-img" />
        </router-link>
      </div>
      <div class="video-side-txt-box">
        <h1 class="video-side-head">Header</h1>
        <p>
          adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Sapien et ligula ullamcorper malesuada proin libero.
          Vulputate ut pharetra sit amet aliquam id diam maecenas ultricies.
          Eget sit amet tellus cras adipiscing enim eu. Vestibulum rhoncus est
          pellentesque elit ullamcorper. Urna nunc id cursus metus aliquam
          eleifend mi in. Massa tincidunt nunc pulvinar sapien. Non quam lacus
        </p>
      </div>
      <div class="bg-side-image-wrap light">
        <div class="bg-img-skew"></div>
        <img src="../assets/bg.jpg" class="big-side-img" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Video Gallery",
};
</script>



