<template>
  <div class="">
    <div class="main-wrap">
      <div class="top-nav-wrap">
        <router-link to="/" class="top-menu-btn">
          <img src="../assets/menu.svg" />Menu
        </router-link>
        <h1 class="top-small-head">3D Tours</h1>
      </div>
      <div class="top-grad"></div>
      <img src="../assets/logo.svg" class="logo" />
      <img src="../assets/Orbia_Logomark_White.png" class="logo2" />      <div class="td-wrap">
        <router-link to="/3d-1" class="video-box">
          <h3 class="video-box-head">Netafim's Visitors Center</h3>
          <div class="video-grad"></div>
          <img src="../assets/Israel-Market.jpg" class="video-bg-img" />
        </router-link>
        <router-link to="/3d-2" class="video-box">
          <h3 class="video-box-head">Azerbaijan PMD</h3>
          <div class="video-grad"></div>
          <img src="../assets/Azerbaijan-PM.jpg" class="video-bg-img" />
        </router-link>
        <router-link to="/3d-3" class="video-box">
          <h3 class="video-box-head">Azerbaijan AFC</h3>
          <div class="video-grad"></div>
          <img src="../assets/Azerbaijan-AFC.jpg" class="video-bg-img" />
        </router-link>
        <router-link to="/3d-4" class="video-box">
          <h3 class="video-box-head">Austria Biohof Geinberg</h3>
          <div class="video-grad"></div>
          <img src="../assets/Austria.jpg" class="video-bg-img" />
        </router-link>
        <router-link to="/3d-5" class="video-box">
          <h3 class="video-box-head">South Africa Emseni</h3>
          <div class="video-grad"></div>
          <img src="../assets/South-Africa.jpg" class="video-bg-img" />
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "3D Gallery",
};
</script>
