var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.videos !== {})?_c('div',{},[_c('div',{staticClass:"full-scr",class:{ open: _vm.fullScreen == false && !_vm.dismissedFullscreen }},[_c('p',[_vm._v("Make this page")]),_c('button',{staticClass:"full-scr-btn",on:{"click":_vm.makeFullscreen}},[_vm._v("Full Screen")]),_c('button',{staticClass:"full-scr-close",on:{"click":function($event){_vm.dismissedFullscreen = true}}},[_c('img',{attrs:{"src":require("../assets/close.svg"),"alt":"Close"}})])]),_c('div',{staticClass:"main-wrap light"},[_c('div',{staticClass:"new-menu-wrap"},[_vm._m(0),_c('div',{staticClass:"boxes-wrap"},[_c('router-link',{staticClass:"video-box2 big1",class:{ loaded: _vm.videoProgress('start') === 100 },attrs:{"to":"/video/start"}},[_c('h3',{staticClass:"video-box-head"},[_vm._v(" Gakon Netafim Video"),_c('br'),_vm._v(" \"From Start to Success We've got you Covered\" ")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/start.jpg")}}),_c('LoadingBar',{attrs:{"progress":_vm.videoProgress('start')}})],1),_c('router-link',{staticClass:"video-box2 loaded",attrs:{"to":"/presentation"}},[_c('h3',{staticClass:"video-box-head"},[_vm._v("Presentation")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/gakon.jpeg")}})]),_vm._m(1),_c('router-link',{staticClass:"video-box2 loaded",attrs:{"to":"/video/map"}},[_c('h3',{staticClass:"video-box-head"},[_vm._v("World Map")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/map.jpg")}})])],1)]),_c('div',{staticClass:"new-menu-wrap wide"},[_vm._m(2),_c('div',{staticClass:"boxes-wrap biggerold"},[_c('router-link',{staticClass:"video-box2",class:{
          loaded: _vm.videoProgress('netafim') === 100,
        },attrs:{"to":"/video/netafim"}},[_c('LoadingBar',{attrs:{"progress":_vm.videoProgress('netafim')}}),_c('h3',{staticClass:"video-box-head"},[_vm._v("Greenhouse Benefits")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/video pics/07_01_Netafim_Green-house.jpg")}})],1),_c('router-link',{staticClass:"video-box2 big3 loaded",attrs:{"to":"/3d-tour"}},[_c('h3',{staticClass:"video-box-head"},[_vm._v("3D Tours")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/bg.jpg")}})]),_c('router-link',{staticClass:"video-box2",class:{
          loaded: _vm.videoProgress('croatia') === 100,
        },attrs:{"to":"/video/croatia"}},[_c('h3',{staticClass:"video-box-head"},[_vm._v("Agrokor "),_c('br'),_vm._v("Croatia")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/video pics/Croatia-Tomatoes-greenhouse-projects-.jpg")}}),_c('LoadingBar',{attrs:{"progress":_vm.videoProgress('croatia')}})],1),_c('router-link',{staticClass:"video-box2",class:{
          loaded: _vm.videos.building && _vm.videos.building.progress === 100,
        },attrs:{"to":"/video/building"}},[_c('h3',{staticClass:"video-box-head"},[_vm._v("Building a Greenhouse")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/video pics/GAKON--building-a-greenhouse.jpg")}})]),_c('router-link',{staticClass:"video-box2",class:{
          loaded: _vm.videoProgress('vietnam') === 100,
        },attrs:{"to":"/video/vietnam"}},[_c('h3',{staticClass:"video-box-head"},[_vm._v(" VinEco "),_c('br'),_vm._v(" Vietnam ")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/video pics/Vietnam-greenhouse-project.jpg")}}),_c('LoadingBar',{attrs:{"progress":_vm.videoProgress('vietnam')}})],1),_c('router-link',{staticClass:"video-box2",class:{
          loaded: _vm.videos.cannabis && _vm.videos.cannabis.progress === 100,
        },attrs:{"to":"/video/cannabis"}},[_c('h3',{staticClass:"video-box-head"},[_vm._v("Medical Cannabis in Greenhouse")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/cannabis.jpg")}}),_c('LoadingBar',{attrs:{"progress":_vm.videoProgress('cannabis')}})],1),_c('router-link',{staticClass:"video-box2",class:{
          loaded: _vm.videoProgress('atyau') === 100,
        },attrs:{"to":"/video/atyau"}},[_c('h3',{staticClass:"video-box-head"},[_vm._v("Atyau Sauda Kazakhstan")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/video pics/Atyau-Kazakhstan-Glasshouse-Project.jpg")}}),_c('LoadingBar',{attrs:{"progress":_vm.videoProgress('atyau')}})],1),_c('router-link',{staticClass:"video-box2",class:{
          loaded: _vm.videos.netbow && _vm.videos.netbow.progress === 100,
        },attrs:{"to":"/video/netbow"}},[_c('h3',{staticClass:"video-box-head"},[_vm._v("Netbow")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/netbow.jpg")}}),_c('LoadingBar',{attrs:{"progress":_vm.videoProgress('netbow')}})],1),_c('router-link',{staticClass:"video-box2",class:{
          loaded: _vm.videos.cottonex && _vm.videos.cottonex.progress === 100,
        },attrs:{"to":"/video/cottonex"}},[_c('h3',{staticClass:"video-box-head"},[_vm._v("Cottonex")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/cottonex.jpg")}}),_c('LoadingBar',{attrs:{"progress":_vm.videoProgress('cottonex')}})],1)],1)]),_c('img',{staticClass:"logo",attrs:{"src":require("../assets/logo.svg")}}),_c('img',{staticClass:"logo2",attrs:{"src":require("../assets/Orbia_Logomark_White.png")}})]),_c('div',{staticClass:"settings"},[_c('button',{on:{"click":function($event){_vm.clearData = true}}},[_vm._v("⚙")]),(_vm.clearData)?_c('button',{on:{"click":_vm.clearLocalData}},[_vm._v("Clear All Cache")]):_vm._e()])]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-head-wrap"},[_c('div',{staticClass:"new-head-side"}),_c('h1',{staticClass:"new-head"},[_vm._v("Gakon Netafim")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"video-box2",attrs:{"href":"https://www.netafim.com/en/greenhouse/","target":"_blank"}},[_c('h3',{staticClass:"video-box-head loaded"},[_vm._v("Website")]),_c('div',{staticClass:"video-grad"}),_c('img',{staticClass:"video-bg-img",attrs:{"src":require("../assets/website.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"new-head-wrap"},[_c('div',{staticClass:"new-head-side"}),_c('h1',{staticClass:"new-head"},[_vm._v("Greenhouse Projects")])])
}]

export { render, staticRenderFns }