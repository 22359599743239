<template>
  <div class="">

    <div class="main-wrap">
      <div class="top-nav-wrap">
        <router-link to="/3d-tours/tours" class="top-menu-btn">
          <img src="../assets/menu.svg" />Menu
        </router-link>
        <h1 class="top-small-head">{{ nestedData.title }}</h1>
      </div>
      <div class="top-grad"></div>

      <div class="main-media">
        <iframe class="iframe" :src="nestedData.link" :title="nestedData.title"></iframe>
      </div>
      <img src="../assets/logo.svg" class="logo" />
      <img src="../assets/Orbia_Logomark_White.png" class="logo2" />
        </div>
  </div>
</template>

<script>
export default {
  name: "3D Tour",
  data() {
    return {
      nestedData: null
    };
  },
  async beforeMount() {
    const { slug } = this.$route.params;
    const splitSlug = slug.split('.')
    const { data: nestedData } = await this.$db
      .collection("menuLayout")
      .item(splitSlug[0])
      .collection("submenu")
      .item(splitSlug[1])

      .get();

    this.nestedData = nestedData;
  }

};
</script>