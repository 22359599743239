<template >
  <div class="">
    <div class="main-wrap">
      <div class="top-nav-wrap">
        <router-link to="/" class="top-menu-btn">
          <img src="../assets/menu.svg" />Menu
        </router-link>
        <h1 class="top-small-head">Product Catalogue</h1>
      </div>
      <div class="top-grad"></div>
      <div class="nav-btns-wrap">
        <a href="#" class="nav-btn"> <img src="../assets/back.svg" /></a>
        <a href="" class="nav-btn"> <img src="../assets/next.svg" /></a>
      </div>
      <div class="main-media"><img src="../assets/about-us.jpg" /></div>
      <div class="index-media">
        <a href="" class="link-thumbnail">
          <img src="../assets/gh-structre5.jpg" /></a
        ><a href="" class="link-thumbnail active">
          <img src="../assets/hero-water-1.jpg" /></a
        ><a href="" class="link-thumbnail">
          <img
            src="../assets/A2179E6D-7E28-43E4-A7A45FA6177AC916-china-baofeng-solar-panel-project.jpg" /></a
        ><a href="" class="link-thumbnail">
          <img src="../assets/dji_0021.jpg" /></a
        ><a href="" class="link-thumbnail">
          <img src="../assets/gh-technology2-1.jpg" /></a
        ><a href="" class="link-thumbnail"> <img src="../assets/bg.jpg" /></a
        ><a href="" class="link-thumbnail"> <img src="../assets/bg.jpg" /></a
        ><a href="" class="link-thumbnail"> <img src="../assets/bg.jpg" /></a>
      </div>
      <div class="bot-grad"></div>
      <img src="../assets/logo.svg" class="logo" />
      <img src="../assets/Orbia_Logomark_White.png" class="logo2" />    </div>
  </div>
</template>
<script>
export default {
  name: "Product Catalogue",
};
</script>



