<template>
  <div class="" v-if="videos !== {}">
    <div class="full-scr" :class="{ open: fullScreen == false && !dismissedFullscreen }">
      <p>Make this page</p>
      <button @click="makeFullscreen" class="full-scr-btn">Full Screen</button>
      <button @click="dismissedFullscreen = true" class="full-scr-close">
        <img src="../assets/close.svg" alt="Close" />
      </button>
    </div>
    <div class="main-wrap light">
      <div class="new-menu-wrap">
        <div class="new-head-wrap">
          <div class="new-head-side"></div>
          <h1 class="new-head">Gakon Netafim</h1>
        </div>
        <div class="boxes-wrap new">
          <div v-for="menuItem in menuLayout" :key="menuItem._id" class="video-box2" :class="{ loaded: videoProgress(menuItem.slug) === 100 }">
            <router-link v-if="menuItem.videoLink" :to="`/videos/${menuItem.slug}`">
              <h3 class="video-box-head" v-html="menuItem.label"></h3>
              <div class="video-grad"></div>
              <img :src="menuItem.thumbnail.imageURL" class="video-bg-img" />
              <LoadingBar :progress="videoProgress(menuItem.slug)" />
            </router-link>
            <a v-else-if="menuItem.externalLink" :href="menuItem.externalLink" target="_blank">
              <h3 class="video-box-head" v-html="menuItem.label"></h3>
              <div class="video-grad"></div>
              <img :src="menuItem.thumbnail.imageURL" class="video-bg-img" />
            </a>

            <router-link v-else-if="menuItem.submenu && menuItem.submenu.length" :to="`/3d-tours/${menuItem.slug}`">
              <h3 class="video-box-head" v-html="menuItem.label"></h3>
              <div class="video-grad"></div>
              <img :src="menuItem.thumbnail.imageURL" class="video-bg-img" />
            </router-link>

            <router-link v-else-if="menuItem.presentationImages" :to="`/presentations/${menuItem.slug}`">
              <h3 class="video-box-head" v-html="menuItem.label"></h3>
              <div class="video-grad"></div>
              <img :src="menuItem.thumbnail.imageURL" class="video-bg-img" />
            </router-link>
          </div>
        </div>
      </div>


      <div class="new-menu-wrap wide new2">
        <div class="new-head-wrap">
          <div class="new-head-side"></div>
          <h1 class="new-head">Greenhouse Projects</h1>
        </div>
        <div class="boxes-wrap bigger flex">
          <div v-for="menuItem2 in menuLayout2" :key="menuItem2._id" class="video-box2" :class="{ loaded: videoProgress(menuItem2.slug) === 100 }">
            <router-link v-if="menuItem2.videoLink" :to="`/videos/${menuItem2.slug}`">
              <h3 class="video-box-head" v-html="menuItem2.label"></h3>
              <div class="video-grad"></div>
              <img :src="menuItem2.thumbnail.imageURL" class="video-bg-img" />
              <LoadingBar :progress="videoProgress(menuItem2.slug)" />
            </router-link>
            <a v-else-if="menuItem2.externalLink" :href="menuItem2.externalLink" target="_blank">
              <h3 class="video-box-head" v-html="menuItem2.label"></h3>
              <div class="video-grad"></div>
              <img :src="menuItem2.thumbnail.imageURL" class="video-bg-img" />
            </a>

            <router-link v-else-if="menuItem2.submenu && menuItem2.submenu.length" :to="`/3d-tours/${menuItem2.slug}`">
              <h3 class="video-box-head" v-html="menuItem2.label"></h3>
              <div class="video-grad"></div>
              <img :src="menuItem2.thumbnail.imageURL" class="video-bg-img" />
            </router-link>

            <router-link v-else-if="menuItem2.presentationImages" :to="`/presentations/${menuItem2.slug}`">
              <h3 class="video-box-head" v-html="menuItem2.label"></h3>
              <div class="video-grad"></div>
              <img :src="menuItem2.thumbnail.imageURL" class="video-bg-img" />
            </router-link>


          </div>

        </div>
      </div>

      <img src="../assets/logo.svg" class="logo" />
      <img src="../assets/Orbia_Logomark_White.png" class="logo2" />    </div>
    <div class="settings">
      <button @click="clearData = true">⚙</button>
      <button v-if="clearData" @click="clearLocalData">Clear All Cache</button>
    </div>
  </div>
</template>

<script>
import LoadingBar from "../components/loadingBar.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  data: () => ({
    videos: {},
    clearData: false,
    dismissedFullscreen: false,
    fullScreen: null,
    menuLayout: {},
    menuLayout2: {}

  }),
  computed: {
    ...mapGetters(["videoProgress"]),
  },
  mounted() {
    this.checkifFullScreen();
    document.body.addEventListener("fullscreenchange", () =>
      this.checkifFullScreen()
    );
    this.$store.dispatch("downloadAllVideos");
  },
  async beforeMount() {
    let { data: menuLayout } = await this.$db
      .collection("menuLayout")
      .sort("order")
      .query("parent.value", "=", "Gako Netafim")
      .query("draft", "=", false)
      .everything()
      .get();
    this.menuLayout = menuLayout;
    let { data: menuLayout2 } = await this.$db
      .collection("menuLayout")
      .sort("order")
      .query("parent.value", "=", "Greenhouse Projects")
      .everything()
      .get();
    this.menuLayout2 = menuLayout2;
  },
  methods: {
    clearLocalData() {
      this.$localDB.collection("menuLayout").delete();
      this.clearData = false;
      window.location.reload();
    },
    makeFullscreen() {
      document.body.requestFullscreen();
    },
    checkifFullScreen() {
      setTimeout(() => {
        this.fullScreen =
          window.innerWidth == screen.width &&
          window.innerHeight == screen.height;
      }, 500);
    },
  },
  components: { LoadingBar },
};
</script>

<style>
.settings {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: black;
  color: white;
  padding: 7px 20px;
  z-index: 99999;
}

.settings button {
  background: transparent;
}
</style>

